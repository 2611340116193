import React from "react"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = ({data, location }) => {

    const content = data.contentfulPage;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const {metaDescription, metaImage, metaKeywords, pageTitle} = content

    const imagePath = `https:${metaImage.file.url}`

    return (
        <Layout page={location.pathname} props={content} title={pageTitle}>
            <SEO title={pageTitle} image={imagePath} />
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="image" content={`https:${metaImage.file.url}`} />
                <meta property="og:image" content={`https:${metaImage.file.url}`} />
                <meta name="twitter:image" content={`https:${metaImage.file.url}`} />
            </Helmet>
            {richText}
        </Layout>
    )
}

export default Page;

export const pageQuery = graphql`
    query pageQuery($slug: String) {
        contentfulPage(slug: {eq: $slug}) {
            slug
            canonicalLink
            metaDescription
            metaImage {
                file {
                    url
                }
                title
            }
            metaKeywords
            pageTitle
            content {
                json
            }
        }
    }`
